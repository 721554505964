.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    height: 100vh; /* Ensure the container takes full height of the viewport */
    box-sizing: border-box; /* Include padding in the height calculation */
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center all elements within the form */
}

.allocation-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    width: 100%;
}

.allocation-form > div,
.allocation-form select,
.allocation-form input[type="number"] {
    margin-right: 10px;
    padding: 5px;
}

.allocation-form select,
.allocation-form input[type="number"] {
    width: auto;
}

/* If you want the select inputs to be the same width, you can set a min-width */
.allocation-form select,
.allocation-form input[type="number"] {
    min-width: 100px;
}

.buttons-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

/* Style for your add allocation button */
.add-allocation-btn {
    border-radius: 10px;
    width: 70px;
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.add-absence-btn {
    border-radius: 10px;
    max-width: 70px;
    background-color: #6961fb;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.delete-consultant-btn {
    border-radius: 10px;
    max-width: 70px;
    min-width: 70px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.add-absence-btn:hover {
    background-color: #6961fb;
}

.add-allocation-btn:hover {
    background-color: #38823b;
}

/* Center the submit button */
.submit-btn {
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px; /* Add margin to the top */
    align-self: center; /* Center the button within the form */
}
