.consultant-card {
    background-color: #fff; /* White background for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    margin: 1rem auto; /* Centers the card with space around */
    padding: 0.5rem 1rem; /* Padding inside the card */
    border-radius: 10px; /* Rounded corners for the card */
    display: flex;
    align-items: center; /* Vertically aligns items in the center */
    justify-content: space-between; /* Spaces out the name and hours */
    width: 80%; /* Adjust based on your design preference */
    height: 250px;
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  }
  
  .consultant-info > h2 {
    color: #333; /* Dark grey color for the text */
    font-size: 1.25rem; /* Sets a specific font size */
    font-weight: normal; /* Standard font weight */
    margin: 0; /* Removes default margins */
    width: 100px;
  }
  
  .consultant-hours-bar {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 1rem;
    height: 100%;
    align-items: flex-end;  /* Align items to the bottom */
}
  
.bar {
  background-color: #333; /* Dark grey color for the bar */
  color: white; /* White text color for contrast */
  padding: 0.5rem; /* Padding inside the bar */
  border-radius: 10px; /* Rounded corners for the bar */
  margin-right: 0.1rem; /* Spacing between bars */
  height: 40px; /* Fixed height for consistency */
  min-width: 80px; /* Minimum width for the bar to ensure readability */
  max-width: 80px;
  display: flex;
  align-items: center; /* Center the text vertically */
  justify-content: center; /* Center the text horizontally */
  flex: 0 0 auto; /* Prevent bars from stretching */
  position: relative; /* Needed for absolute positioning of the tooltip */
  font-size: 0.9rem; /* Increase the font size for better readability */
  cursor: pointer;
}

.bar:hover {
  background-color: #444; /* Slightly lighter or darker when hovered */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
