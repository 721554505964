.form-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.error-message {
    color: red;
}

.table-chart-container {
    display: flex;
    gap: 20px;
    align-items: flex-start; /* Align items at the top */
}

.fixed-table-container {
    width: 50%; /* Adjust width to make room for the chart */
}

.fixed-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
}

.fixed-table th, .fixed-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd; /* subtle line for separation */
}

.fixed-table th {
    background-color: #4CAF50; /* green background for headers */
    color: white;
    border-bottom: 2px solid #444; /* darker line at the bottom of the header */
}

.fixed-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* zebra-striping for rows */
}

.fixed-table tbody tr:hover {
    background-color: #ddd; /* hover effect for rows */
}

/* Right align the last header and cells for the activate/deactivate button */
.fixed-table th:last-child, .fixed-table td:last-child {
    text-align: right;
    width: 10%;
}

/* Add responsive design */
@media screen and (max-width: 600px) {
    .table-chart-container {
        flex-direction: column; /* Stack table and chart vertically on small screens */
    }
    .fixed-table-container, .chart-container {
        width: 100%;
    }
    .fixed-table thead {
        display: none; /* hide headers on small screens */
    }
    .fixed-table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: none;
    }
    .fixed-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }
    .fixed-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        font-weight: bold;
    }
}

.chart-container {
    width: 50%;
    min-width: 300px; /* Ensure chart has a minimum width */
}
