.dashboard {
    display: flex;
    justify-content: space-between; /* Aligns children (table and pie chart) on opposite sides */
    align-items: flex-start; /* Aligns children at the top */
    padding: 20px;
}

#absenceTable {
    flex: 1 1 50%; /* Allows the table to take up half of the available space */
    margin-right: 20px; /* Adds some space between the table and the pie chart */
    margin-left: 15%;
}

.pieChartContainer {
    flex: 1 1 50%; /* Allows the pie chart container to take up the other half */
    display: flex;
    justify-content: center; /* Centers the pie chart horizontally */
    align-items: center; /* Centers the pie chart vertically */
}


#absenceTable thead {
    background-color: #f5f5f5;
}

#absenceTable th, #absenceTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

#absenceTable th:nth-child(2), #absenceTable td:nth-child(2) {
    min-width: 75px;
}

#absenceTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

#absenceTable tr{
    height: 55px;
}

#absenceTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f03e3e;
    color: white;
}

#piechart {
    margin-right: 15%;
}

.absence-header button {
    margin-top: 15px;
}