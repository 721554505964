.container {
    margin: auto;
    padding: 20px;
    max-width: 1000px; /* Adjust as needed */
}

.active-inactive {
    border: none;
    cursor: pointer;
    padding: 8px 8px; /* Smaller padding */
    line-height: 1; /* Add line-height for vertical alignment */
    vertical-align: middle; /* Align the button vertically */
    border-radius: 20px; /* Adjust border-radius for rounded shape */
    font-size: 16px; /* Adjust font size */
    font-weight: bold;
    margin: 0; /* Reset margin */
    transition: box-shadow 0.3s ease; /* Transition for box-shadow */
}

.active-inactive:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Box-shadow effect on hover */
}

.inactive {
    background-color: #4CAF50; /* Green color for active */
    color: white;
}

.active {
    background-color: #FF5733; /* Red color for inactive */
    color: white;
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
}

th, td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd; /* subtle line for separation */
}

th {
    background-color: #4CAF50; /* green background for headers */
    color: white;
    border-bottom: 2px solid #444; /* darker line at the bottom of the header */
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* zebra-striping for rows */
}

tbody tr:hover {
    background-color: #ddd; /* hover effect for rows */
}

/* Right align the last header and cells for the activate/deactivate button */
th:last-child, td:last-child {
    text-align: right;
    width: 10%;
}

table th.sortable {
    cursor: pointer;
    position: relative;
    padding-right: 20px;
}

table th.sortable:hover {
    color: rgb(195, 247, 98); /* Change the color on hover */
    text-decoration: underline; /* Optionally underline the text */
}

table th.sortable::after {
    content: '▼';
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: transparent;
    transition: color 0.2s;
}

table th.sortable:hover::after {
    color: rgb(195, 247, 98); /* Show the arrow on hover */
}

/* Add responsive design */
@media screen and (max-width: 600px) {
    table {
        width: 100%;
    }
    thead {
        display: none; /* hide headers on small screens */
    }
    tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: none;
    }
    td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }
    td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        font-weight: bold;
    }
}

body {
    background-color: white;
}
