.heatmap-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Aligns content to stretch across the width */
    width: 100%; /* Ensures the container uses up all available width */
}

.year-selector-container {
    width: 100%; /* Adjusts based on the parent width, you can set a max-width */
    display: flex;
    justify-content: center; /* Aligns the selector to the center */
    padding-bottom: 10px; /* Adds some space below the selector */
}

.consultant-row, .week-labels {
    display: flex;
    align-items: center;
    width: 100%; /* Ensures the row uses up all available space */
}

.consultant-name {
    color: black;
    position: sticky; /* Make the name stick to the left */
    left: 0; /* Align it to the left */
    background-color: rgb(216, 216, 216); /* Optional: to ensure text is readable over other content */
    z-index: 1; /* Make sure it stays on top of other scrollable content */
    width: 150px; /* Fixed width for all names */
    flex-shrink: 0; /* Prevents the name from shrinking */
    text-align: right; /* Keeps the alignment tidy */
    font-size: 15px;
    height: 52px;
    padding-right: 10px; /* Space before the grid starts */
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Align the text to the center vertically */
    justify-content: flex-end; /* Align the text to the right */
}

.name-color {
    background-color: white;
}

.heatmap-cell, .week-label {
    color: black;
    flex: 1; /* Allows cells to grow and fill available space */
    min-width: 50px; /* Minimum width before the cell starts shrinking */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 1px; /* Consistent spacing between cells */
}

.heatmap-cell {
    margin-bottom: 1px;
}

.heatmap-wrapper {
    width: 100%; /* Ensures the wrapper takes up the entire width */
    overflow-x: auto; /* Allows horizontal scrolling */
    overflow-y: hidden; /* Prevents vertical scrolling */
}

.hover {
    cursor: pointer;
}

.hover:hover {
    transform: scale(1.2); /* Scale up slightly on hover */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.delete-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 7px;
}

.total-absence-summary-container {
    width: 100%; /* Ensures it takes up the full width of the viewport */
    display: flex;
    justify-content: center; /* Centers the summary text */
    position: relative; /* Keeps it positioned relative to the viewport */
    background-color: white; /* Optional: Background color to ensure visibility */
    padding: 10px 0; /* Padding for better spacing */
    z-index: 10; /* Ensures it stays on top of other content */
}