.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.allocation-form {
    margin-bottom: 10px;
}

input,
select {
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-btn {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.loading {
    text-align: center;
    margin-top: 20px;
}

.error {
    color: #ff0000;
    margin-top: 10px;
}
